import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseForm, ControlCategory, ModuleKeywords } from '@shared/classes';
import { ViewModeService } from '@shared/services';
import { ControlCategoryDataService } from '../../service/data/control-category-data.service';

@Component({
  selector: 'app-control-category-item-form',
  templateUrl: './control-category-item-form.component.html',
  styleUrl: './control-category-item-form.component.scss',
})
export class ControlCategoryItemFormComponent extends BaseForm<ControlCategory> implements OnInit {
  contextVariables: any[] = [];
  @Input() itemId;
  constructor(
    public viewModeService: ViewModeService,
    private requestService: ControlCategoryDataService
  ) {
    super(viewModeService, ModuleKeywords.ControlCategory);
  }

  ngOnInit(): void {}

  getData() {
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat()
      : this.getChangedFormValues().updateItems;
  }

  setData(data: any) {
    this.patchFormData({ ...data });
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      parent: new FormControl(null),
      description: new FormControl(null),
      sourceOfControl: new FormControl(null),
      sourceReference: new FormControl(null),
      uniqueIdentifier: new FormControl(null),
    });
  }
}
